import { Box, LinearProgress, List, Typography } from "@mui/material"
import axios from "axios"
import _ from "lodash";
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import OrderCard from "./OrderCard";

function english_ordinal_suffix(dt){
    return dt.getDate()+(dt.getDate() % 10 === 1 && dt.getDate() !== 11 ? 'st' : (dt.getDate() % 10 === 2 && dt.getDate() !== 12 ? 'nd' : (dt.getDate() % 10 === 3 && dt.getDate() !== 13 ? 'rd' : 'th'))); 
}

const OrdersIndex = props => {
    const { enqueueSnackbar } = useSnackbar()
    const [orders, setOrders] = useState([])
    const [loadingOrders, setLoadingOrders] = useState(true)

    useEffect(() => {
        const controller = new AbortController();
        setLoadingOrders(true)
        async function getData(){
            await axios.get('https://retailbs.com.au/seasonal/get_orders.php', {signal: controller.signal}).then(res=>{
                if(res.data){
                    const grouped = _.groupBy(res.data, order => order.collection)
                    setOrders(grouped)
                }}).catch(err=>{
                    enqueueSnackbar("An error occured loading orders, please try again.", {variant: 'error'})
                })
                setLoadingOrders(false)
            }
        getData()
        return () => {
            controller.abort();
        }
    }, [enqueueSnackbar])
    
    if(loadingOrders){
        return <Box sx={{ width: '100%', mt:5 }}>
            <Typography variant="overline">Loading Orders...</Typography>
        <LinearProgress color="warning" />
      </Box>
    }

    if(orders.length === 0){
        return <Box sx={{ width: '100%', mt:5 }}>
            <Typography variant="overline">No orders have been placed yet</Typography>
      </Box>
    }

    let ordersList = Object.entries(orders).map(([key, value])=>{
        let day = new Date(key)
        let dayFormatted = day.toLocaleDateString("en-us", {weekday: "long"}) + " " + english_ordinal_suffix(day) + " " + day.toLocaleDateString("en-us", {month: "long"}) + ", " + day.getFullYear()
        return <List key={Math.random()}>
            <Typography variant="h6">{dayFormatted}</Typography>
            {value.map((val) => <OrderCard key={val.id} order={val} />)}
        </List>
    })

    return (
        <>{ordersList}</>
    )
}

export default OrdersIndex