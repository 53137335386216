import { AppBar, MenuItem, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link } from "react-router-dom";

const Header = props => {

    return(
        <><AppBar position="static">
            <Toolbar disableGutters>
                <ShoppingCartIcon sx={{ mx: 1 }} />
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                    mr: 1,
                    fontWeight: 700,
                    letterSpacing: '.1rem',
                    color: 'inherit',
                    textDecoration: 'none',
                    }}
                >
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex'} }}>
                    <MenuItem sx={{'a:link, a:active, a:visited, a:hover':{color: 'white', textDecoration: 'none', textTransform: 'uppercase'}}}>
                        <Link to={"/"}><Typography textAlign="center"><strong>PRODUCTS</strong></Typography></Link>
                    </MenuItem>
                    <MenuItem sx={{'a:link, a:active, a:visited, a:hover':{color: 'white', textDecoration: 'none', textTransform: 'uppercase'}}}>
                        <Link to={"/orders"}><Typography textAlign="center"><strong>ORDERS</strong></Typography></Link>
                    </MenuItem>
                    <MenuItem sx={{'a:link, a:active, a:visited, a:hover':{color: 'white', textDecoration: 'none', textTransform: 'uppercase'}}}>
                        <Link to={"/consolidatedorders"}><Typography textAlign="center"><strong>CONSOLIDATED ORDERS</strong></Typography></Link>
                    </MenuItem>
                </Box>
            </Toolbar>
        </AppBar>
        </>
    )
}

export default Header