import { Button, Chip, Grid, List, ListItem, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { SidebarContext } from '../Context/SidebarContext';
import ProductTile from './ProductTile';
import axios from 'axios';
import { useSnackbar } from 'notistack';

function ProductIndex() {
    const { enqueueSnackbar } = useSnackbar()
    const { setIsOpen, setId, setCategoryList } = useContext(SidebarContext);
    const [categories, setCategories] = useState([])
    const [products, setProducts] = useState([])

    useEffect(() => {
      axios.get("https://retailbs.com.au/seasonal/get_categories.php").then(res=>{
        setCategories(res.data)
        axios.get("https://retailbs.com.au/seasonal/get_products.php").then(res=>{
          setProducts(res.data)
        }).catch(err=>{
          enqueueSnackbar('An error occured loading products, please try again!', { variant: 'error'});
        })
      }).catch(err=>{
        enqueueSnackbar('An error occured loading categories, please try again!', { variant: 'error'});
      })
    }, [enqueueSnackbar])    

    let categoryList

    if(categories.length > 0){
        categoryList = categories.map((val)=>{
            let productList = []
            if(products.length > 0){
                productList = products.filter(prod => prod.category === val.id);
                productList = productList.sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                 }).map((res)=> {
                    return <ProductTile key={res.id} product={res} categories={categories} />
                })
            }
            if(productList.length === 0){
                productList = <ListItem><Typography variant='overline' component='div'>No items in this category</Typography></ListItem>
            }
            return <Grid key={val.name} item xs={12}>
                <Chip
                    id={val.link}
                    label={val.name}
                    sx={{
                        color: 'white',
                        fontSize: '1.5rem',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        background: 'linear-gradient(to right bottom, #36EAEF, #6B0AC9)',
                        p: 2
                    }}
                />
                    <List>{productList}</List>
            </Grid>
        })
    }

    return (<>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Button fullWidth variant='contained' color='secondary' sx={{ml: 2}} onClick={()=>{setId(null);setCategoryList(categories);setIsOpen(true);}}>Add Product</Button>
            </Grid>
            {categoryList}
        </Grid>
    </>);
  }
  
  export default ProductIndex;
  