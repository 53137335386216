import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Drawer, FormControl, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { SidebarContext } from '../Context/SidebarContext';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SideDrawer = props => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const { isOpen, setIsOpen, id, setId, categories } = useContext(SidebarContext);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isWeighted, setIsWeighted] = useState("0")
    const nameRef = useRef()
    const descriptionRef = useRef()
    const categoryRef = useRef()
    const weighRef = useRef()
    const averageWeightRef = useRef("0")
    const estimatedPriceRef = useRef("0")

    useEffect(() => {
        if(id){
            setIsWeighted(id.is_weigh)
        }
    }, [id])
    

    function addProduct(e){
        setIsSubmitting(true)
        e.preventDefault()
        let data = {
            name: nameRef.current.value,
            caption: descriptionRef.current.value,
            category: categoryRef.current.value,
            is_weigh: weighRef.current.value,
            weight: (averageWeightRef.current) ? averageWeightRef.current.value : "0",
            price: (estimatedPriceRef.current) ? estimatedPriceRef.current.value : "0",
        }
        async function add(){
            await axios.post("https://retailbs.com.au/seasonal/add_product.php", data).then(res=>{
                if(res.data === "added"){
                    enqueueSnackbar("Product has been added successfully!", {variant:'success'})
                    setIsOpen(false)
                    setId(null)
                    setIsSubmitting(false)
                    navigate(0)
                } else {
                    enqueueSnackbar(res.data, {variant:'error'}) 
                    setIsSubmitting(false)
                }
            }).catch(err=>{
                enqueueSnackbar("An error occured adding the product, please try again!", {variant:'error'}) 
                setIsSubmitting(false)
            })
        }
        add()
    }

    function editProduct(e){
        setIsSubmitting(true)
        e.preventDefault()
        console.log(averageWeightRef)
        let data = {
            id: id.id,
            name: nameRef.current.value,
            caption: descriptionRef.current.value,
            category: categoryRef.current.value,
            is_weigh: weighRef.current.value,
            weight: (averageWeightRef.current) ? averageWeightRef.current.value : "0",
            price: (estimatedPriceRef.current) ? estimatedPriceRef.current.value : "0",
        }
        async function edit(){
            await axios.post("https://retailbs.com.au/seasonal/edit_product.php", data).then(res=>{
                if(res.data === "edited"){
                    enqueueSnackbar("Product has been edited successfully!", {variant:'success'})
                    setIsOpen(false)
                    setId(null)
                    setIsSubmitting(false)
                    navigate(0)
                } else {
                    enqueueSnackbar(res.data, {variant:'error'}) 
                    setIsSubmitting(false)
                }
            }).catch(err=>{
                enqueueSnackbar("An error occured editing the product, please try again!", {variant:'error'}) 
                setIsSubmitting(false)
            })
        }
        edit()
    }
    
    let categoryList

    if(categories.length > 0){
        categoryList = categories.map((val)=>{
            return <MenuItem key={val.id} value={val.id}>{val.name}</MenuItem>
        })
    }

    let estimateWeightForm
    let estimatePriceForm

    if(isWeighted === "1"){
        estimateWeightForm = <><Grid item xs={12}>
        <Typography variant='overline'><b>Average Weight (In KG) (e.g. 800g is .80) *</b></Typography>
        <FormControl fullWidth>
            <TextField name="avg_weight" type="text" inputRef={averageWeightRef} required={(isWeighted === "1")} fullWidth variant='outlined' defaultValue={(id) ? (id.weight) ? id.weight : "0" : "0"} />
        </FormControl>
    </Grid>
    <Grid item xs={12}>
        <Typography variant='overline'><b>Estimated Price (Per KG) (Leave 0 for blank)</b></Typography>
        <FormControl fullWidth>
            <TextField name="est_price" type="text" inputRef={estimatedPriceRef} fullWidth variant='outlined' defaultValue={(id) ? (id.price) ? id.price : "0" : "0"} />
        </FormControl>
    </Grid>
    </>
    } else {
        estimateWeightForm = <Grid item xs={12}>
        <Typography variant='overline'><b>Estimated Price (Leave 0 for blank)</b></Typography>
        <FormControl fullWidth>
            <TextField name="est_price" type="text" inputRef={estimatedPriceRef} fullWidth variant='outlined' defaultValue={(id) ? (id.price) ? id.price : "0" : "0"} />
        </FormControl>
    </Grid>
    }

    if(id){
        return (
            <Drawer
                sx={{
                    "& .MuiDrawer-paper": {
                        maxWidth: 400,
                        width: '100%'
                    }
                }}
                anchor='right'
                open={isOpen}
                onClose={()=>setIsOpen(!isOpen)}
            ><Box sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                marginX: 'auto',
                mt: 2,
                mb: 3
            }}>
                <Typography color='primary' variant="h5"><b>Edit Product</b></Typography>
            </Box>
                <form onSubmit={(e)=>editProduct(e)}>
                
                <Grid container spacing={2} sx={{p:2}}>
                    <Grid item xs={12}>
                        <Typography variant='overline'><b>Product Name *</b></Typography>
                        <FormControl fullWidth>
                            <TextField name="name" required inputRef={nameRef} fullWidth variant='outlined' defaultValue={id.name} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='overline'><b>Description</b></Typography>
                        <FormControl fullWidth>
                            <TextField name="description" inputRef={descriptionRef} fullWidth variant='outlined' defaultValue={id.caption} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='overline'><b>Category *</b></Typography>
                        <FormControl fullWidth>
                            <Select
                                inputRef={categoryRef}
                                defaultValue={id.category}
                                variant='outlined'
                                required
                            >
                                {categoryList}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='overline'><b>Random Weight? *</b></Typography>
                        <FormControl fullWidth>
                            <Select
                                inputRef={weighRef}
                                defaultValue={id.is_weigh}
                                onChange={(e)=>setIsWeighted(e.target.value)}
                                variant='outlined'
                                required
                            >
                                <MenuItem value="0">No</MenuItem>
                                <MenuItem value="1">Yes</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {estimateWeightForm}
                    {estimatePriceForm}
                    <Grid item xs={12} sx={{mt:2}}>
                        <Button variant="contained" type='submit' color="success" fullWidth disabled={isSubmitting}>Edit Product</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="error" fullWidth disabled={isSubmitting} onClick={()=>{setId(null);setIsOpen(false)}}>Cancel</Button>
                    </Grid>
                </Grid>
                </form>
            </Drawer>
        )
    }

    return (
        <Drawer
            sx={{
                "& .MuiDrawer-paper": {
                    maxWidth: 400,
                    width: '100%'
                }
            }}
            anchor='right'
            open={isOpen}
            onClose={()=>setIsOpen(!isOpen)}
        ><Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginX: 'auto',
            mt: 2,
            mb: 3
        }}>
            <Typography color='primary' variant="h5"><b>Add Product</b></Typography>
        </Box>
            <form onSubmit={(e)=>addProduct(e)}>
            
            <Grid container spacing={2} sx={{p:2}}>
                <Grid item xs={12}>
                    <Typography variant='overline'>Product Name *</Typography>
                    <FormControl fullWidth>
                        <TextField name="name" required inputRef={nameRef} fullWidth variant='outlined' defaultValue="" />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='overline'>Description</Typography>
                    <FormControl fullWidth>
                        <TextField name="description" inputRef={descriptionRef} fullWidth variant='outlined' defaultValue="" />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='overline'>Category *</Typography>
                    <FormControl fullWidth>
                        <Select
                            inputRef={categoryRef}
                            defaultValue="1"
                            variant='outlined'
                            required
                        >
                            {categoryList}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='overline'>Random Weight? *</Typography>
                    <FormControl fullWidth>
                        <Select
                            inputRef={weighRef}
                            defaultValue="0"
                            variant='outlined'
                            onChange={(e)=>setIsWeighted(e.target.value)}
                            required
                        >
                            <MenuItem value="0">No</MenuItem>
                            <MenuItem value="1">Yes</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {estimateWeightForm}
                {estimatePriceForm}
                <Grid item xs={12} sx={{mt:2}}>
                    <Button variant="contained" type='submit' color="success" fullWidth disabled={isSubmitting}>Add Product</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="error" fullWidth disabled={isSubmitting} onClick={()=>{setId(null);setIsOpen(false)}}>Cancel</Button>
                </Grid>
            </Grid>
            </form>
        </Drawer>
    )
}

export default SideDrawer