import { ArrowForward } from "@mui/icons-material"
import { Card, CardContent, Grid, ListItem, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

const OrderCard = props => {
    const { order } = props
    const navigate = useNavigate()

    return (
        <ListItem onClick={()=>{navigate("/order/"+order.id)}} sx={{backgroundColor: 'rgba(0,100,100, .1)', p:1, my:1, ':hover': {transform: 'scale(1.02)', backgroundColor: 'rgba(0,100,100, .2)'}, transition: 'transform .5s ease-in-out'}}>
            <Card sx={{width: '100%', cursor:'pointer'}} elevation={0}>
                <CardContent sx={{'&:last-child': {p:1}}}>
                    <Grid container spacing={0}>
                        <Grid item xs={11}>
                            <Typography variant="body2" component="div" sx={{textTransform: 'uppercase'}}><b>{order.name}</b></Typography>
                            <Typography variant="overline" component="div" sx={{textTransform: 'uppercase'}}>{order.phone} ({order.email})</Typography>
                        </Grid>
                        <Grid item xs={1} display="flex" justifyContent='center' alignItems='center'>
                            <ArrowForward fontSize="large" color="primary" />
                        </Grid>
                    </Grid>
                    
                </CardContent>
            </Card>
           
        </ListItem>
    )
}

export default OrderCard